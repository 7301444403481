<template>
    <div>
        <div>
            <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="true"
                margin="[10,10,10,10]"
                filename="DayBook"
                :paginate-elements-by-height="1000"
                :pdf-quality="2"
                pdf-format="A4"
                pdf-orientation="portrait"
                :pdf-content-width="isprint==1?'800px':'1150px'"
                :manual-pagination="true"
                @progress="onProgress($event)"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)"
                ref="html2Pdf"
            >
                <section slot="pdf-content">
                    <section class="pdf-item">
                        <div class="col-md-12" >
                            <div class="col-md-12">
                                <h3 class="page-header" v-if="isprint==0">
                                    <i class="fa fa fa-book animated bounceInDown show-info"></i>
                                    Day Book
                                </h3>
                                <h3 class="flex-row justify-content-center" v-if="isprint==1">
                                    <i class="fa fa fa-book animated bounceInDown show-info"></i>
                                    <label for=""> Day Book</label>
                                </h3>
                            </div>
                        </div>
                        <div class="flex-row justify-content-center" v-if="localviewno==1">
                            <div style="text-align:center ;" v-if="todate!=''">
                                <label>As on {{moment(fromdate).format('DD-MM-YYYY')}} to {{moment(todate).format('DD-MM-YYYY')}}</label>
                            </div>
                            <div style="text-align:center;" v-if="entryfrom!=''">
                                <label>As on Entry Date: {{moment(entryfrom).format('DD-MM-YYYY')}} to {{moment(entryto).format('DD-MM-YYYY')}}</label>
                            </div>
                        </div>
                        <div class="card-body" style="border:1px solid #e9dbdb;font-sixe:13px;" v-if="localviewno==1 && isprint==0">
                            <div class="col-md-12">
                                <div class="row">
                                    <label class="col-lg-4 co-md-3 control-label ">
                                        <h5> Search Records</h5>
                                    </label>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 row">
                                            <label><b>From Date</b></label>
                                            <input class="form-control form-cascade-control input-small" type="date" v-model="fromdate">
                                        </div>

                                        <div class="col-md-4 row">
                                            <label><strong>To Date</strong></label>
                                            <input class="form-control form-cascade-control input-small" type="date"
                                                v-model="todate">
                                        </div>
                                        <div class="col-md-4 row">
                                            <label><strong>Entry By</strong></label>
                                            <select class="form-control form-cascade-control input-small"  v-model="enteredbyid">
                                                <option :value="0">---Select----</option>
                                                <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 row">
                                            <label><strong>Entry Date from</strong></label>
                                            <input class="form-control form-cascade-control input-small" type="date"
                                                v-model="entryfrom">
                                        </div>
                                        <div class="col-md-4 row">
                                            <label><strong>Entry Date To</strong></label>
                                            <input class="form-control form-cascade-control input-small" type="date"
                                                v-model="entryto">
                                        </div>
                                        <div class="col-md-2 flex-between-row">
                                            <button @click="refresh()" class="btn bg-success text-white username btnsearch"
                                                style="margin-top:20px;" type="button">Search</button>
                                            <button @click="clearSearch()" class="btn bg-primary text-white username btnsearch"
                                                style="margin-top:20px;" type="button"><i class='bx bx-x bx-rotate-90 bx-tada' style='color:#ff0000'  ></i>Clear</button>
                                        </div>
                                        <div class="col-md-2">
                                            <button
                                                type="button"
                                                id="btnprint"
                                                style="margin-top: 22px;"
                                                class="btn btn-xs pdf"
                                                value="Go to Print"
                                                @click="generateReport()"
                                            >
                                            <i class="bx bxs-file-pdf" style="font-size:40px"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" style="border:1px solid #e9dbdb;font-sixe:13px;" v-if="localviewno==1">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="table-font">
                                            <th style="width:10%">Serial No.</th>
                                            <th aria-colspan="large">Books</th>
                                            <th>Amount</th>
                                            <th>Records</th>
                                        </tr>
                                    </thead>
                                    <tbody style="font-size: 14px;">
                                        <tr class="table-font">
                                            <td>1</td>
                                            <td><a href="#" @click="localviewno = 2">Sale Book</a></td>
                                            <td>{{ daysale }}</td>
                                            <td>{{salecount}}</td>
                                        </tr>
                                        <tr class="table-font">
                                            <td>2</td>
                                            <td><a href="#" @click="localviewno = 3">Collection Book</a></td>
                                            <td>{{ collection }}</td>
                                            <td>{{ collectioncount }}</td>
                                        </tr>
                                        <tr class="table-font">
                                            <td>3</td>
                                            <td><a href="#" @click="localviewno = 4">Customer Reg.</a></td>
                                            <td>0.0</td>
                                            <td>{{ userscount }}</td>
                                        </tr>
                                        <tr class="table-font">
                                            <td>4</td>
                                            <td><a href="#" @click="localviewno = 2">Purchase Book</a></td>
                                            <td>0.0</td>
                                            <td>0</td>
                                        </tr>
                                    <!--  <tr>
                                            <td>5</td>
                                            <td><a href="#" @click="localviewno = 4">Expense Book</a></td>
                                            <td>{{ dayexpense }}</td>
                                        </tr> -->
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div class="card-body mt-20" v-if="localviewno==1">
                            <div class="text-center mb-10">
                                <label for="">SALES EXECUTIVE BOOKS</label>
                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead class="table-font">
                                        <tr>
                                            <th style="width:7%">Serial No.</th>
                                            <th style="width:10%">Exe. Type</th>
                                            <th style="width:10%">Vsdigi Id</th>
                                            <th>Executive Name</th>
                                            <th>Sales</th>
                                            <th>Sales Value</th>
                                            <th>D.P.</th>
                                            <th>EMI</th>
                                            <th>Scheme</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item,index) in executivedata" :key="item.id">
                                        <tr class="table-font">
                                            <td>{{index+1}}</td>
                                            <td>{{item.isemployee==1?'Team':'Advisor'}}</td>
                                            <td>{{item.generatedid}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{getsalescount(item)}}</td>
                                            <td>{{getsalesamount(item)}}</td>
                                            <td>{{getdp(item)}}</td>
                                            <td>{{getemi(item)}}</td>
                                            <td>{{ getscheme(item) }}</td>
                                        </tr>
                                        <div v-if="(index!=0 && index==8 && index!=17) || (index!=0 && index!=19 && index%17==0)" class="html2pdf__page-break" style="margin-bottom:20px;"></div>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><label for="">Total :</label></td>
                                            <td><label for="">{{gettotalsales()}}</label></td>
                                            <td><label for="">{{gettotalsaleamt()}}</label></td>
                                            <td><label for="">{{gettotaldp()}}</label></td>
                                            <td><label for="">{{gettotalemi()}}</label></td>
                                            <td><label for="">{{ gettotalscheme() }}</label></td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                                <div class="text-center">
                                        <label>Grand Total Amount : </label>
                                        <span class="collection_total">{{ gettotaldp()+gettotalemi()+gettotalscheme() }}</span>
                                    </div>
                                <!-- <div class="col-md-12 flex-between-row mb-10" style="margin-top: 20px">
                                    <div class="col-md-3">
                                        <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                                            <option value=10>10</option>
                                            <option value=25>25</option>
                                            <option value=50>50</option>
                                            <option value=100>100</option>
                                        </select>
                                    </div>
                                    <nav aria-label="Page navigation example" style="text-align: right !important">   
                                        <paginate
                                            style="margin: 0px !important; float: right"
                                            :page-count="noofpages"
                                            :click-handler="clickCallback"
                                            :prev-text="'&laquo;'"
                                            :next-text="'&raquo;'"
                                            :container-class="'pagination'">
                                        </paginate>
                                    </nav>
                                </div> -->
                            </div>
                        </div>
                    </section>
                </section>
            </vue-html2pdf>
        </div>
        <sale-book v-if="localviewno==2" :daysale="daysale" :salecount="salecount" :todate="todate" :fromdate="fromdate" :entryfrom="entryfrom" :entryto="entryto" :enteredbyid="enteredbyid"></sale-book>
        <collection-book v-if="localviewno==3" :collection="collection" :collectioncount="collectioncount" :todate="todate" :fromdate="fromdate" :entryfrom="entryfrom" :entryto="entryto" :enteredbyid="enteredbyid"></collection-book>
        <customer-book v-if="localviewno==4" :users="userscount" :todate="todate" :fromdate="fromdate" :entryfrom="entryfrom" :entryto="entryto" :enteredbyid="enteredbyid"></customer-book>
    </div>
</template>
<script>
import moment from 'moment';
// import Paginate from "vuejs-paginate";
import SaleBook from './SaleBook.vue';
import CollectionBook from './CollectionBook.vue';
import CustomerBook from './CustomerBook.vue';
import { mapGetters } from 'vuex';
import VueHtml2pdf from 'vue-html2pdf'
// import EmployeesBook from './EmployeesBook.vue'
export default {
    components:{SaleBook,CollectionBook,CustomerBook,VueHtml2pdf},
    data() {
        return {
            todate:'',
            fromdate:'',
            entryfrom:'',
            enteredbyid:'',
            entryto:'',
            daysale:0,
            collection:0,
            userscount:0,
            localviewno:1,
            collectondetails:[],
            saledetails:[],
            userdetails:[],
            moment:moment,
            salecount:0,
            collectioncount:0,
            executivedata:[],
            empbookrec:[],
            currentpage:1,
            noofrec:10,
            executivecount:0,
            isprint:0,
        }
    },
    mounted() {
        //this.refresh();
        this.$store.dispatch('fetchemployees')
    },
    computed:{
        ...mapGetters(['employees']),
        noofpages(){
            return Math.ceil(parseInt(this.executivecount) / parseInt(this.noofrec))
        }
    },
    methods: {
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.refresh();
        },
        refresh(){
            this.$store.commit("assignloadingstatus",1)
            let param={todate:this.todate,fromdate:this.fromdate,entryfrom:this.entryfrom,
                entryto:this.entryto,enteredbyid:this.enteredbyid}
            // this.$http.post('api/daybook/details/vsdigi',param)
			// 	.then(response=>this.processDetailResponse(response.data))
			// 	.catch(error=>console.log(error));
            this.$http.post('api/daybook/sum/vsdigi',param)
				.then(response=>this.processfetchResponse(response.data))
				.catch(error=>console.log(error));
                
            this.$http.post('api/daybook/user',param)
				.then(response=>this.processfetchEmpResponse(response.data))
				.catch(error=>console.log(error));

            // let param1={noofrec:this.noofrec,currentpage:this.currentpage}
            // this.$http.post('api/users/emp/advisors',param1)
			// 	.then(response=>this.processfetchExecutiveResponse(response.data))
			// 	.catch(error=>console.log(error));

            // this.$http.post('api/users/emp/advisors/count')
			// 	.then(response=>this.processfetchExecutiveCountResponse(response.data))
			// 	.catch(error=>console.log(error));
        },
        processfetchExecutiveCountResponse(data){
            this.executivecount=data
        },
        processfetchEmpResponse(data){
           this.empbookrec=data
           this.executivedata=data.users
        },  
        processfetchExecutiveResponse(data){
        this.executivedata=data
            
        },
        processfetchResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.collection=data.collection
            this.daysale=data.salebook
            this.userscount=data.users
            this.salecount=data.salecount
            this.collectioncount=data.collectioncount
        },
        getsalesamount(item){
            if(this.empbookrec.salesamount !=null){
                let rec=this.empbookrec.salesamount.find(element=>element.advisorid==item.id)
                if(rec !== undefined){
                    return rec.amount;
                }else{
                    return '0'
                }
            }else{
                return '0'
            }
        },
        getsalescount(item){
            if(this.empbookrec.salescount !=null){
                let rec=this.empbookrec.salescount.find(element=>element.advisorid==item.id)
                if(rec !== undefined){
                    return rec.count;
                }else{
                    return '0'
                }
            }else{
                return '0'
            }
        },
        
        getdp(item){
            if(this.empbookrec.dp !=null){
                let rec=this.empbookrec.dp.find(element=>element.collectedbyid==item.id)
                if(rec !== undefined){
                    return rec.amount;
                }else{
                    return '0'
                }
            }else{
                return '0'
            }
        },
        getemi(item){
            if(this.empbookrec.emi!=null){
                let rec=this.empbookrec.emi.find(element=>element.collectedbyid==item.id)
                if(rec !== undefined){
                    return rec.amount;
                }else{
                    return '0'
                }
            }else{
                return '0'
            }
        },
        getscheme(item){
            if(this.empbookrec.scheme!=null){
                let rec=this.empbookrec.scheme.find(element=>element.collectedbyid==item.id)
                if(rec !== undefined){
                    return rec.amount;
                }else{
                    return '0'
                }
            }else{
                return '0'
            }
        },
        gettotalsales(){
            if(this.empbookrec.salescount!=null){
                let count=0
                this.empbookrec.salescount.forEach(element=>{
                    count=count+element.count
                })
                return count
            }else{
                return 0
            }
        },
        gettotalsaleamt(){
            if(this.empbookrec.salesamount!=null){
                let count=0.0
                this.empbookrec.salesamount.forEach(element=>{
                    count=count + parseFloat(element.amount)
                })
                return count
            }else{
                return 0
            }
        },
        gettotaldp(){
            if(this.empbookrec.dp!=null){
                let amount=0
                this.empbookrec.dp.forEach(element=>{
                    amount=(amount)+parseFloat(element.amount)
                })
                return amount
            }else{
                return 0
            }
        },
        gettotalemi(){
            if(this.empbookrec.emi!=null){
                let amount=0
                this.empbookrec.emi.forEach(element=>{
                    amount=(amount)+parseFloat(element.amount)
                })
                return amount
            }else{
                return 0
            }
        },
        gettotalscheme(){
            if(this.empbookrec.scheme!=null){
                let amount=0
                this.empbookrec.scheme.forEach(element=>{
                    amount=(amount)+parseFloat(element.amount)
                })
                return amount
            }else{
                return 0
            }
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        generateReport () {
            this.isprint=1
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(){
            this.$parent.generate=false
            this.isprint=0
        },
        clearSearch(){
            this.todate=''
            this.fromdate=''
            this.entryfrom=''
            this.enteredbyid=''
            this.entryto=''
        }
        
    },
}
</script>
<style scoped>
.table-font{
    color: black;
}
</style>