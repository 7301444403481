<template>

<vue-html2pdf
    :show-layout="true"
    :float-layout="false"
    :enable-download="false"
    :preview-modal="true"
    margin="[10,10,10,10]"
    filename="DayBook"
    :paginate-elements-by-height="1400"
    :pdf-quality="2"
    pdf-format="A4"
    pdf-orientation="portrait"
    :pdf-content-width="isprint==1 ? '800px':'1300px'"
    :manual-pagination="true"
    @progress="onProgress($event)"
    @beforeDownload="beforeDownload($event)"
    @hasDownloaded="hasDownloaded($event)"
    ref="html2Pdf"
>
    <section slot="pdf-content">
        <section class="pdf-item">
            <div class="card-body">
                <h3 class="flex-row justify-content-center" v-if="isprint==1">
                        <i class="fa fa fa-book animated bounceInDown show-info"></i>
                        <label for=""> Day Book</label>
                </h3>
                <div style="text-align:center ;" v-if="todate!=''">
                    <label>As on {{moment(fromdate).format('DD-MM-YYYY')}} to {{moment(todate).format('DD-MM-YYYY')}}</label>
                </div>
                <div style="text-align:center;" v-if="entryfrom!=''">
                    <label>As on Entry Date : {{moment(entryfrom).format('DD-MM-YYYY')}} to {{moment(entryto).format('DD-MM-YYYY')}}</label>
                </div>
                <div class="headding flex-between-row">
                    <h4>Sale Book</h4>
                    <div class="flex-around-row" v-if="isprint==0">
                        <div style="margin-right:25px">   
                            <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                        </div> 
                        <div class="col-md-2">
                            <button v-if="isprint==0"
                                type="button"
                                id="btnprint"
                                class="btn btn-xs pdf"
                                value="Go to Print"
                                @click="generateReport()"
                            >
                            <i class="bx bxs-file-pdf" style="font-size:40px"></i>
                            </button>
                            <label for="" v-else>{{progress}}</label>
                        </div>
                    </div>
                </div>
                <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                    <div class="mt-20">
                        <table class="table table-bordered table-hover table-striped display">
                            <thead class="table-font">
                                <tr>
                                    <th>#</th>
                                    <th>Lan</th>
                                    <th >Sales No.</th>
                                    <th>Customer Id</th>
                                    <th>Name</th>
                                    <th>Product</th>
                                    <th>Sale Value</th>
                                    <th>Grand Total</th>
                                    <th>D.P.</th>
                                    <th>Delivered By</th>
                                    <th>Plan</th>
                                    <th>Sale By</th>
                                    <th>Entry By</th>
                                </tr>
                            </thead>
                            <tbody class="table-font" v-for="(item,index) in transactiondetails" v-bind:key="item.id">
                                <tr>
                                    <td>{{index+1}}</td>
                                    <td><a v-if="item.loandetails!=null" href="#" @click="loandetails(item.loandetails.id)">
                                            <div >
                                                <span style="font-size:11px"> {{item.loandetails.manualid}}</span>
                                            </div>
                                        </a>
                                    </td>
                                    <td v-if="isprint==0">{{item.usersequence}}</td>
                                    <td>
                                        <div v-if="item.user!=null && item.customername==null">{{item.user.generatedid}}</div>
                                        <div v-else>{{item.customername}}</div>
                                    </td>
                                    <td>{{ item.user==null?'':item.user.name }}</td>
                                    <td >
                                        <div v-for="value in item.items" v-bind:key="value.id" class="mb-5">
                                            <div style="text-transform: capitalize;">{{ value.product.displayproduct }}</div>
                                            <div> {{ value.product.modelno }}</div>
                                            <div v-for="attribute in value.product.attribute" v-bind:key="attribute.id">
                                            {{ getcompany(attribute.attribute_value_ids) }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ item.loandetails!=null ? item.loandetails?.billedamount : item.amountwithtax }}</td>
                                    <td>
                                        <span v-if="item?.loandetails!=null"> {{ parseInt(item.loandetails.billedamount) + parseInt(getEmiCost(item)) }}</span>
                                        <span v-else>{{ parseInt(item?.amountwithtax) }}</span>
                                    </td>
                                    
                                    <td><div v-if="item.loandetails!=null">{{item.loandetails.downpayment}}</div></td>
                                    <td><span v-if="item.saleby!=null">{{item.saleby.name}}</span></td>
                                    <td v-if="item.loandetails!=null">EMI</td>
                                    <td v-else>Cash</td>
                                    <td>
                                        <div v-if="item.items!=null">
                                            {{ item.advisor!=null ?item.advisor.name:'' }} <br/>
                                            {{moment(item.transactiondate).format('DD-MM-YYYY')}}
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="item.enteredby!=null">{{item.enteredby.name}}</div>
                                        <div>{{moment(item.created_at).format('DD-MM-YYYY')}}<br>{{moment(item.created_at).format('h:m:s A')}}</div>
                                    </td>
                                    <!-- <td><span v-if="item.loandetails!=null">{{setaccounttype(item)}}</span></td> -->
                                </tr>
                                <div v-if="page1(index)" class="html2pdf__page-break" style="margin-bottom:20px;"></div>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Total sale value :</label>
                        <span class="btn btn-success ml-10 text-white" >{{ daysale }}</span>
                    </div>
                    <div class="col-md-4">
                        <label>No. of Records :</label>
                        <span class="btn btn-prime ml-10 text-white" >{{ salecount }}</span>
                    </div>
                    <div class="col-md-4">
                        <label>Total Grand Value :</label>
                        <span class="btn btn-prime ml-10 text-white" >{{ getGrandValue() }}</span>
                    </div>
                   
                </div>
            </div>
        </section>
    </section>
</vue-html2pdf>
</template>

<script>
import moment from 'moment';
import VueHtml2pdf from 'vue-html2pdf'
import { mapGetters } from 'vuex';
export default {
    props:['todate','fromdate','entryfrom','entryto','enteredbyid','salecount','daysale'],
    components:{VueHtml2pdf},
    data() {
        return {
            transactiondetails:[],
            currentpage:1,
            noofrec:10,
            moment:moment,
            isprint:0
        }
    },
    mounted() {
        this.refresh();
        
       this.$store.dispatch("fetchattributes")
    },
    computed:{
	    noofpages(){
            return Math.ceil(parseInt(this.salecount) / parseInt(this.noofrec))
        },
        ...mapGetters(["attributes"]),
        
        
    },
    methods: {
        page1(index){
            if(index==3){
                return true
            }
            if((index-3)%9==0){
                return true
            }
            return false
        },
        loadMore: function() {
            this.busy=true
            if(this.currentpage<=this.noofpages){
                setTimeout(() => {
                    this.refresh();
                    this.busy = false;
                }, 1000);
            }
        },
        setaccounttype(item){
            if(item.loandetails!=null){
                if(item.loandetails.accountstatus==0){
                    return "Active"
                }else if(item.loandetails.accountstatus==1){
                    return "Clear Sale"
                }else if(item.loandetails.accountstatus==2){
                    return "Defaulter Sale"
                }else if(item.loandetails.accountstatus==3){
                    return "Legal Sale"
                }else if(item.loandetails.accountstatus==4){
                    return "Concesation Account"
                }else if(item.loandetails.accountstatus==5){
                    return "Closed Sale"
                }
            }
        },
        refresh(){
            this.$store.commit("assignloadingstatus",1)
            let param={todate:this.todate,fromdate:this.fromdate,entryfrom:this.entryfrom,
                entryto:this.entryto,enteredbyid:this.enteredbyid,noofrec:this.noofrec
                ,currentpage:this.currentpage}
            this.$http.post('api/daybook/details/vsdigi',param)
				.then(response=>this.processDetailResponse(response.data))
				.catch(error=>console.log(error));
            this.currentpage=this.currentpage+1
        },
        processDetailResponse(data){
            this.$store.commit("assignloadingstatus",0)
            if(this.currentpage>1){
                this.transactiondetails=this.transactiondetails.concat(data.salebook)
                this.busy=false
            }else{
                this.transactiondetails=data.salebook
            }
        },
        back(){
            this.$parent.localviewno=1
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        generateReport () {
            this.isprint=1
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(){
            this.$parent.generate=false
            this.isprint=0
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        getEmiCost(item) {
            let items = item?.items
            let temp = 0
            let total = 0
            for(let i=0; i < items?.length; i++){
                let json = JSON.parse(items[i].product?.emi_cost)?.emi_cost
                temp = parseFloat(temp) + parseFloat(json) ??0
            }
            if(temp > 0){
                total = parseInt(item.loandetails?.numberofemi) *  parseFloat(temp)
            }
            return parseFloat(total).toFixed(2) ?? 0;
        },
        getGrandValue(){
            let rows = this.transactiondetails
            let temp = 0
            for (let i = 0; i < rows.length; i++){
                let loandetails = rows[i].loandetails?.billedamount ?? 0
                temp = temp + (parseFloat(loandetails) ?? 0 + parseFloat(this.getEmiCost(rows[i]))??0)
            }
            return temp
        }
        
    },
}
</script>