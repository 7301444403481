<template>
    <tbody class="table-font">
        <tr v-if="item!=null">
            <td>{{index+1 }}</td>
            <td  class="visible-lg">
                <a href="#" @click="detail(item)">{{ item.generatedid }}</a>
                <span v-if="item.verified==1"><i class='bx bxs-badge-check' style="color:#0299f7;font-size: 15px;"></i></span>
            </td>
            <td>{{item.name}}</td> 
            <td><display-mobiles :mobiles="item.mobiles"></display-mobiles></td>
            <td><display-first-address :addresses="item.addressess"></display-first-address></td>
            <!-- <td v-if="item.addressess!=null">{{item.addressess.landmark}}</td> -->
            <td>{{  item.registation_date != null ? moment(item.registation_date).format("DD-MM-YYYY") : ''}}</td>
            <td style="width:14%">
                <template v-if="item.referraluser!=null">
                    {{ item.referraluser.generatedid }}<br>
                    {{item.referraluser.name}}
                    <display-mobiles :mobiles="item.referraluser.mobiles"></display-mobiles>
                </template>
            </td>
            <td ><div v-if="item.enteredby!=null">
                    {{item.enteredby.name}}<br>
                    {{moment(item.created_at).format('DD-MM-YYYY')}}<br>
                    {{moment(item.created_at).format('hh:mm:ss')}}
                </div>
            </td>
            <!-- <td>{{item.creditlimit}}</td> -->
        </tr>
        <div v-if="page" class="html2pdf__page-break" style="margin-bottom:20px;"></div>
    </tbody>
</template>
<script>
import DisplayMobiles from "../../components/mobile/DisplayMobiles.vue";
import DisplayFirstAddress from "../../components/address/DisplayFirstAddress.vue";
import moment from "moment";
export default {
    components:{DisplayFirstAddress,DisplayMobiles},
    props:['item','index','page'],
    data() {
        return {
            moment:moment
        }
    },
}
</script>