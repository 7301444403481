<template>
    <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        margin="[10,10,10,10]"
        filename="DayBook"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        pdf-format="A4"
        pdf-orientation="portrait"
        pdf-content-width="auto"
        :manual-pagination="true"
        @progress="onProgress($event)"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="card-body">
                    <h3 class="flex-row justify-content-center" v-if="isprint==1">
                        <i class="fa fa fa-book animated bounceInDown show-info"></i>
                        <label for=""> Day Book</label>
                    </h3>
                    <div style="text-align:center ;" v-if="todate!=''">
                        <label>As on {{moment(fromdate).format('DD-MM-YYYY')}} to {{moment(todate).format('DD-MM-YYYY')}}</label>
                    </div>
                    <div style="text-align:center;" v-if="entryfrom!=''">
                        <label>As on Entry Date: {{moment(entryfrom).format('DD-MM-YYYY')}} to {{moment(entryto).format('DD-MM-YYYY')}}</label>
                    </div>
                    <div class="headding flex-between-row">
                        <h4>Customer's Registration</h4>
                        <div class="flex-around-row" v-if="isprint==0">
                            <div style="margin-right:25px">   
                                <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                            </div> 
                            <div class="col-md-2">
                                <button v-if="isprint==0"
                                    type="button"
                                    id="btnprint"
                                    class="btn btn-xs pdf"
                                    value="Go to Print"
                                    @click="generateReport()"
                                >
                                <i class="bx bxs-file-pdf" style="font-size:40px"></i>
                                </button>
                                <label for="" v-else>{{progress}}</label>
                            </div>
                        </div>
                    </div>
                    <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="0">
                        <div class="mt-20">
                            <table class="table table-bordered table-hover table-striped display">
                                <thead class="table-font">
                                    <tr>
                                        <th class="visible-lg">#</th>
                                        <th class="visible-lg" >VSDIGI-ID</th>
                                        <th class="visible-lg">Name</th>
                                        <th>Contact</th>
                                        <th>Address</th>
                                        <!-- <th>Landmark</th> -->
                                        <th>Registation_date</th>
                                        <th>Introducer Details</th>
                                        <th>Entry By</th>
                                        <!-- <th>Credit Limit</th> -->
                                    </tr>
                                </thead>
                                <customer-book-row v-for="(item,index) in customersdata" v-bind:key="item.id" :item="item" :index="index" :page="page1(index)"></customer-book-row>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </vue-html2pdf>
</template>

<script>
// import Paginate from "vuejs-paginate";

import moment from 'moment';
import Constants from "../../components/utilities/Constants.vue";
import VueHtml2pdf from 'vue-html2pdf'
import CustomerBookRow from '@/pages/daybook/CustomerBookRow'
export default {
    mixins:[Constants],
    props:['todate','fromdate','entryfrom','entryto','enteredbyid','users'],
    components:{VueHtml2pdf,CustomerBookRow},
    data() {
        return {
            transactiondetails:[],
            currentpage:1,
            noofrec:20,
            moment:moment,
            customersdata:[],
            isprint:0,
        }
    },
    computed:{
        noofpages(){
            return Math.ceil(parseInt(this.users) / parseInt(this.noofrec))
        }
    },
        methods: {
        page1(index){
            if(index==8){
                return true
            }
            if((index-8)%9==0){
                return true
            }
            return false
        },
        loadMore: function() {
            this.busy=true
            if(this.currentpage<=this.noofpages){
                setTimeout(() => {
                    this.refresh();
                    this.busy = false;
                },1000);
            }
        },
        refresh(){
            this.$store.commit("assignloadingstatus",1)
            let param={todate:this.todate,fromdate:this.fromdate,entryfrom:this.entryfrom,
                entryto:this.entryto,enteredbyid:this.enteredbyid,noofrec:this.noofrec
                ,currentpage:this.currentpage}
            this.$http.post('api/daybook/details/vsdigi',param)
                .then(response=>this.processDetailResponse(response.data))
                .catch(error=>console.log(error));
                this.currentpage=this.currentpage+1
        },
        processDetailResponse(data){
            this.$store.commit("assignloadingstatus",0)
            if(this.currentpage>1){
                this.customersdata=this.customersdata.concat(data.users)
                this.busy=false
            }else{
                this.customersdata=data.users
            }
        },
        back(){
            this.$parent.localviewno=1
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        generateReport () {
            this.isprint=1
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(){
            this.$parent.generate=false
            this.isprint=0
        },
        
       
    },
}
</script>