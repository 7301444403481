<template>
    <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="true"
        margin="[10,10,10,10]" filename="DayBook" :paginate-elements-by-height="1400" :pdf-quality="2" pdf-format="A4"
        pdf-orientation="portrait" pdf-content-width="auto" :manual-pagination="true" @progress="onProgress($event)"
        @beforeDownload="beforeDownload($event)" @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="card-body">
                    <h3 class="flex-row justify-content-center" v-if="isprint == 1">
                        <i class="fa fa fa-book animated bounceInDown show-info"></i>
                        <label for=""> Day Book</label>
                    </h3>
                    <div style="text-align:center ;" v-if="todate != ''">
                        <label>As on {{ moment(fromdate).format('DD-MM-YYYY') }} to
                            {{ moment(todate).format('DD-MM-YYYY') }}</label>
                    </div>
                    <div style="text-align:center;" v-if="entryfrom != ''">
                        <label>As on Entry Date: {{ moment(entryfrom).format('DD-MM-YYYY') }} to
                            {{ moment(entryto).format('DD-MM-YYYY') }}</label>
                    </div>
                    <div class="headding flex-between-row">
                        <h4>Collection Book</h4>
                        <div class="flex-around-row" v-if="isprint == 0">
                            <div style="margin-right:25px">
                                <button @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i> Back</button>
                            </div>
                            <div class="col-md-2">
                                <button v-if="isprint == 0" type="button" id="btnprint" class="btn btn-xs pdf"
                                    value="Go to Print" @click="generateReport()">
                                    <i class="bx bxs-file-pdf" style="font-size:40px"></i>
                                </button>
                                <label for="" v-else>{{ progress }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-cascade" v-if="isprint==0">
                        <div class="panel-body">
                            <div class="form-horizontal cascde-forms">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label><strong>VSDIGI-ID</strong></label>
                                        <input type="text" class="form-control form-cascade-control input-small" 
                                            v-model="generatedid">
                                    </div>
                                    <div class="col-md-4">
                                        <label><strong>Payment Type</strong></label>
                                        <select class=" form-control form-cascade-control input-small"  v-model="payment">
                                            <option :value="0">All</option>
                                            <option :value="1">EMI</option>
                                            <option :value="2">Cash Sale</option>
                                            <option :value="4">Scheme</option>
                                            <option :value="5">Downpayment</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 flex-around-row ">
                                        <button @click="search()" class="btn bg-success text-white" style="margin-top:24px;"  
                                            type="button">Search</button>
                                        <button @click="cancel()" class="btn bg-danger text-white" style="margin-top:24px;"
                                            type="button">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                        <div class="mt-20">
                            <table class="table table-bordered table-hover table-striped display">
                                <thead class="table-font">
                                    <tr>
                                        <th>#</th>
                                        <th>LAN No.</th>
                                        <th>Customer Name</th>
                                        <th v-if="isprint==0">Zone</th>
                                        <th v-if="isprint==0">Contact</th>
                                        <th>Address</th>
                                        <th>Rcpt No.</th>
                                        <!-- <th>Amount</th> -->
                                        <th>Payment Date</th>
                                        <th>Receive Payment</th>
                                        <th>Received By</th>
                                        <th style="width:10%">Entry By</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody class="table-font" v-for="(item, index) in allpayments" v-bind:key="item.id">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            <div v-if="item.reftransactiontype == 3 || item.reftransactiontype == 2">
                                                <a href="#"
                                                    v-if="item.loanemi != null && item.loanemi.loanaccount != null && item.paymenttype == 1"
                                                    @click="loandetails(item.loanemi.loanaccount.id)">{{ item.loanemi.loanaccount.manualid }}</a>
                                                <a href="#"
                                                    v-if="item.saletransaction != null && item.saletransaction.loandetails != null && item.paymenttype == 5"
                                                    @click="loandetails(item.saletransaction.loandetails.id)">{{ item.saletransaction.loandetails.manualid }}</a>
                                            </div>
                                            <div v-else-if="item.paymenttype == 4">
                                                <a href="#"
                                                    v-if="item.ongoingschemeemi != null">{{ item.ongoingschemeemi.ongoingscheme.generatedid }}</a>
                                            </div>
                                        </td>
                                        <td>{{ item.customer == null ? '' : item.customer.name }}</td>
                                        <td  v-if="isprint==0">{{ item.customer == null ? '' : getMasterLabelByType(item.customer.zoneid, MASTER_ZONE_TYPES) }}</td>
                                        <td v-if="isprint==0"><display-mobiles v-if="item.customer != null"
                                                :mobiles="item.customer.mobiles"></display-mobiles></td>
                                        <td><display-first-address v-if="item.customer != null"
                                                :addresses="item.customer.addressess"></display-first-address></td>
                                        <td>{{ item.receiptno }}</td>
                                        <!-- <td><span v-if="item.loanemi!=null && item.saletransaction==null"><i class='bx bx-rupee'></i> {{item.loanemi.tobepaid}}</span></td> -->
                                        <td><i class='bx bxs-calendar'></i>
                                            {{ moment(item.transactiondate).format("DD-MM-YYYY") }}</td>
                                        <td>
                                            <span><i class='bx bx-rupee'></i> {{ item.amount }}</span><br>
                                            <span class="badge btn-view" style="margin-top: 16px;"
                                                v-if="item.paymentregister != null">{{ item.paymentregister.name }}</span>
                                        </td>
                                        <td>
                                            <div v-if="item.collectedby != null">{{ item.collectedby.name }}<br>{{ item.collectedby.generatedid }}</div>
                                        </td>

                                        <td>
                                            <span v-if="item.enteredby != null">{{ item.enteredby.name }}</span><br>
                                            {{ moment(item.created_at).format('DD-MM-YYYY') }}<br>
                                            {{ moment(item.created_at).format('hh:mm:s A') }}

                                        </td>
                                        <td>
                                            <span v-if="item.paymenttype == null"></span>
                                            <span v-else-if="item.paymenttype == 1">EMI</span>
                                            <span v-else-if="item.paymenttype == 2">Cash</span>
                                            <span v-else-if="item.paymenttype == 3">Advance</span>
                                            <span v-else-if="item.paymenttype == 4">Scheme</span>
                                            <span v-else-if="item.paymenttype == 5">Downpayment</span>
                                        </td>
                                    </tr>
                                    <div v-if="page1(index)" class="html2pdf__page-break" style="margin-bottom:20px;"></div>
                                </tbody>
                            </table>
                            <div v-if="isprint==0">
                                <div class="mt-30 mb-20">
                                    <h4>All Payment Collection</h4>
                                </div>
                                <div class="row">
                                    <div class="flex-div">
                                        <div>
                                            <h5 style="background: #475c78;color: #fff;text-align: center;padding: 5px;">EMI
                                            </h5>
                                        </div>
                                        <div class="collection-div">
                                            <div>
                                                <label>Cash  </label>
                                                <span class="collection_total" style="margin-left: 30px;"> : {{ getemicash }}</span>
                                            </div>
                                            <div>
                                                <label>Paytm  </label>
                                                <span class="collection_total">: {{ getemipaytm }}</span>
                                            </div>
                                            <div>
                                                <label>Phonepe  </label>
                                                <span class="collection_total" style="margin-left: 5px;">: {{ getemiphonepe }}</span>
                                            </div>
                                            <div>
                                                <label>G. Pay  </label>
                                                <span class="collection_total">: {{ getemigooglepay }}</span>
                                            </div>
                                            <div>
                                                <label>Others  </label>
                                                <span class="collection_total">: {{ getemiother }}</span>
                                            </div>
                                        </div>
                                        <div class="total-amount">
                                            <label>Total  </label>
                                            <span class="collection_total" style="margin-left: 37px;"> : {{ totalemi }}</span>
                                        </div>

                                    </div>
                                    <div class="flex-div" style="border-left: 1px solid gray;">
                                        <div>
                                            <h5 style="background: #475c78;color: #fff;text-align: center;padding: 5px;">D.P.
                                            </h5>
                                        </div>
                                        <div class="collection-div">
                                            <div>
                                                <label>Cash  </label>
                                                <span class="collection_total" style="margin-left: 33px;">: {{ getdpcash }}</span>
                                            </div>
                                            <div>
                                                <label>Paytm  </label>
                                                <span class="collection_total">: {{ getdppaytm }}</span>
                                            </div>
                                            <div>
                                                <label>Phonepe  </label>
                                                <span class="collection_total" style="margin-left: 5px;">: {{ getdpphonepe }}</span>
                                            </div>
                                            <div>
                                                <label>G. Pay  </label>
                                                <span class="collection_total" style="margin-left: 20px;"> : {{ getdpgooglepay }}</span>
                                            </div>
                                            <div>
                                                <label>Others </label>
                                                <span class="collection_total" style="margin-left: 20px;"> : {{ getdpother }}</span>
                                            </div>
                                        </div>
                                        <div class="total-amount">
                                            <label>Total </label>
                                            <span class="collection_total" style="margin-left: 37px;"> : {{ totaldp }}</span>
                                        </div>
                                    </div>
                                    <div class="flex-div" style="border-left: 1px solid gray;">
                                        <div>
                                            <h5 style="background: #475c78;color: #fff;text-align: center;padding: 5px;">Cash
                                                Sale</h5>
                                        </div>

                                        <div class="collection-div">
                                            <div>
                                                <label>Cash  </label>
                                                <span class="collection_total" style="margin-left: 33px;">: {{ getpaycash }}</span>
                                            </div>
                                            <div>
                                                <label>Paytm  </label>
                                                <span class="collection_total"> : {{ getpaypaytm }}</span>
                                            </div>
                                            <div>
                                                <label>Phonepe  </label>
                                                <span class="collection_total" style="margin-left: 5px;">: {{ getpayphonepe }}</span>
                                            </div>
                                            <div>
                                                <label>G. Pay  </label>
                                                <span class="collection_total">: {{ getpaygooglepay }}</span>
                                            </div>
                                            <div>
                                                <label>Others  </label>
                                                <span class="collection_total">: {{ getpayother }}</span>
                                            </div>

                                        </div>
                                        <div class="total-amount">
                                            <label>Total  </label>
                                            <span class="collection_total" style="margin-left: 37px;">: {{ totalpay }}</span>
                                        </div>
                                    </div>
                                    <div class="flex-div" style="border-left: 1px solid gray;">
                                        <div>
                                            <h5 style="background: #475c78;color: #fff;text-align: center;padding: 5px;">Scheme
                                            </h5>
                                        </div>
                                        <div class="collection-div">
                                            <div>
                                                <label>Cash  </label>
                                                <span class="collection_total" style="margin-left: 30px;"> : {{ getschemecash }}</span>
                                            </div>
                                            <div>
                                                <label>Paytm </label>
                                                <span class="collection_total">: {{ getschemepaytm }}</span>
                                            </div>
                                            <div>
                                                <label>Phonepe </label>
                                                <span class="collection_total" style="margin-left: 5px;">: {{ getschemephonepe }}</span>
                                            </div>
                                            <div>
                                                <label>G. Pay  </label>
                                                <span class="collection_total">: {{ getschemegooglepay }}</span>
                                            </div>
                                            <div>
                                                <label>Others  </label>
                                                <span class="collection_total">: {{ getschemeother }}</span>
                                            </div>

                                        </div>
                                        <div class="total-amount">
                                            <label>Total  </label>
                                            <span class="collection_total" style="margin-left: 37px;">: {{ totalscheme }}</span>
                                        </div>
                                    </div>
                                    <div class="flex-div" style="border-left: 1px solid gray;">
                                        <div>
                                            <h5 style="background: #475c78;color: #fff;text-align: center;padding: 5px;">Total
                                            </h5>
                                        </div>
                                        <div class="collection-div">
                                            <div>
                                                <label>Cash  </label>
                                                <span class="collection_total" style="margin-left: 30px;">: {{ totalcash }}</span>
                                            </div>
                                            <div>
                                                <label>Paytm  </label>
                                                <span class="collection_total">: {{ totalpatym }}</span>
                                            </div>
                                            <div>
                                                <label>Phonepe </label>
                                                <span class="collection_total" style="margin-left: 5px;">: {{ totalphonepe }}</span>
                                            </div>
                                            <div>
                                                <label>G. Pay  </label>
                                                <span class="collection_total">: {{ totalgooglepay }}</span>
                                            </div>
                                            <div>
                                                <label>Others  </label>
                                                <span class="collection_total">: {{ totalother }}</span>
                                            </div>
                                        </div>
                                        <div class="total-amount">
                                            <label>Subtotal  </label>
                                            <span class="collection_total"> : {{ subtotal }}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row mt-10 text-end">
                                <div class="col-md-6">
                                    <label>Grand Total : </label>
                                    <span class="total_collection">{{ this.subtotal }}</span>
                                </div>
                                <div class="col-md-6">
                                    <label>No. of records : </label>
                                    <span class="total_collection" style="background-color: brown;">{{ this.record }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </vue-html2pdf>
</template>
    
<script>
import DisplayFirstAddress from "../../components/address/DisplayFirstAddress.vue";
import DisplayMobiles from "../../components/mobile/DisplayMobiles.vue";
import moment from "moment";
import VueHtml2pdf from 'vue-html2pdf'
import Constants from "../../components/utilities/Constants.vue";
import { mapGetters } from "vuex";
export default {
    mixins: [Constants],
    props: ['todate', 'fromdate', 'entryfrom', 'entryto', 'enteredbyid', 'collectioncount', 'collection'],
    components: { DisplayMobiles, DisplayFirstAddress, VueHtml2pdf },
    data() {
        return {
            allpayments: [],
            currentpage: 1,
            noofrec: 20,
            moment: moment,
            busy: false,
            isprint: 0,
            reachedmax: false,
            paymentmode: [],
            generatedid: '',
            payment: '',
            countrow: this.collectioncount,
            record: '',

            getemicash: 0,
            getemigooglepay: 0,
            getemiphonepe: 0,
            getemipaytm: 0,
            getemiother: 0,
            totalemi: 0,

            getpaycash: 0,
            getpaygooglepay: 0,
            getpayphonepe: 0,
            getpaypaytm: 0,
            getpayother: 0,
            totalpay: 0,

            getschemecash: 0,
            getschemegooglepay: 0,
            getschemephonepe: 0,
            getschemepaytm: 0,
            getschemeother: 0,
            totalscheme: 0,

            getdpcash: 0,
            getdpgooglepay: 0,
            getdpphonepe: 0,
            getdppaytm: 0,
            getdpother: 0,
            totaldp: 0,

            totalcash: 0,
            totalpatym: 0,
            totalphonepe: 0,
            totalgooglepay: 0,
            totalother: 0,
            subtotal: 0,
        }
    },
    computed: {
        ...mapGetters([
            'paymenttype'
        ]),
        noofpages() {
            return Math.ceil(parseInt(this.collectioncount) / parseInt(this.noofrec))
        },

    },
   
    watch: {
        currentpage: function (val) {
            if (val == this.noofpages) {
                this.reachedmax = true
            }
        }
    },
    methods: {
        page1(index) {
            if (index == 5) {
                return true
            }
            if ((index - 5) % 7 == 0) {
                return true
            }
            return false
        },
        loadMore: function () {
            this.busy = true
            if (this.currentpage <= this.noofpages) {
                setTimeout(() => {
                    this.refresh();
                    this.busy = false;
                }, 1000);
            }
        },
        refresh() {
            this.$store.commit("assignloadingstatus", 1)
            let param = {
                todate: this.todate, fromdate: this.fromdate, entryfrom: this.entryfrom,
                entryto: this.entryto, enteredbyid: this.enteredbyid, noofrec: this.noofrec
                , currentpage: this.currentpage
            }
            this.$http.post('api/daybook/details/vsdigi', param)
                .then(response => this.processDetailResponse(response.data))
                .catch(error => console.log(error));
            this.currentpage = this.currentpage + 1
        },
        processDetailResponse(data) {
            this.$store.commit("assignloadingstatus", 0)
            if (this.currentpage > 1) {
                this.allpayments = this.allpayments.concat(data.collection);
                this.busy = false
            } else {
                this.allpayments = data.collection
            }
            if (this.collectioncount == this.allpayments.length) {
                this.getEmiPayment();
            }
            this.record = this.allpayments.length;
        },
        back() {
            this.$parent.localviewno = 1
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        generateReport() {
            this.isprint = 1
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded() {
            this.$parent.generate = false
            this.isprint = 0
        },

        typeofcollection(abc, paymentmode) {
            if (paymentmode.paymentregister.id == this.paymenttype['cash'] || paymentmode.paymentregister.id == this.paymenttype['cashpayment']) {
                this[abc + 'cash'] += parseFloat(paymentmode.amount);
            }
            if (paymentmode.paymentregister.id == this.paymenttype['googlepay']) {
                this[abc + 'googlepay'] += parseFloat(paymentmode.amount);
            }
            if (paymentmode.paymentregister.id == this.paymenttype['phonepe']) {
                this[abc + 'phonepe'] += parseFloat(paymentmode.amount);
            }
            if (paymentmode.paymentregister.id == this.paymenttype['paytm']) {
                this[abc + 'paytm'] += parseFloat(paymentmode.amount);
            }
            if (paymentmode.paymentregister.id == this.paymenttype['razorpay'] || paymentmode.paymentregister.id == this.paymenttype['cheque'] || paymentmode.paymentregister.id == this.paymenttype['rtgs'] || paymentmode.paymentregister.id == this.paymenttype['amazonpay']) {
                this[abc + 'other'] += parseFloat(paymentmode.amount);
            }
        },

        // 273  -> Cash Payment
        // 2567 -> Cash
        // 2225 -> Google Pay
        // 2227 -> Phonepe
        // 2229 -> Cheque
        // 2230 -> NEFT/RTGS
        // 6511 -> Paytm
        // 6514 -> Amazon
        // 8630 -> Razorpay


        getEmiPayment() {
            let templist = this.allpayments;
            this.getemicash = 0
            this.getemigooglepay = 0
            this.getemipaytm = 0
            this.getemiphonepe = 0
            this.getemiother = 0

            this.getpaycash = 0;
            this.getpaygooglepay = 0;
            this.getpayphonepe = 0;
            this.getpaypaytm = 0;
            this.getpayother = 0;
           
            this.getschemecash = 0;
            this.getschemegooglepay = 0;
            this.getschemephonepe = 0;
            this.getschemepaytm = 0;
            this.getschemeother = 0;
           
            this.getdpcash = 0;
            this.getdpgooglepay = 0;
            this.getdpphonepe = 0;
            this.getdppaytm = 0;
            this.getdpother = 0;
           
            for (let i = 0; i < templist.length; i++) {
                if (templist[i].paymenttype == 1) {
                    if (templist[i].paymentregister != null) {
                        this.typeofcollection('getemi', templist[i]);
                    }

                }
                if (templist[i].paymenttype == 2) {
                    if (templist[i].paymentregister != null) {

                        this.typeofcollection('getpay', templist[i]);
                    }
                }
                if (templist[i].paymenttype == 4) {
                    if (templist[i].paymentregister != null) {

                        this.typeofcollection('getscheme', templist[i]);
                    }
                }
                if (templist[i].paymenttype == 5) {
                    if (templist[i].paymentregister != null) {
                        this.typeofcollection('getdp', templist[i]);
                    }
                }
            }

            this.totalemi = this.getemicash + this.getemigooglepay + this.getemipaytm + this.getemiphonepe + this.getemiother;
            this.totalpay = this.getpaycash + this.getpaygooglepay + this.getpaypaytm + this.getpayphonepe + this.getpayother;
            this.totalscheme = this.getschemecash + this.getschemegooglepay + this.getschemepaytm + this.getschemephonepe + this.getschemeother;
            this.totaldp = this.getdpcash + this.getdpgooglepay + this.getdppaytm + this.getdpphonepe + this.getdpother;

            this.totalcash = this.getemicash + this.getpaycash + this.getdpcash + this.getschemecash;
            this.totalpatym = this.getemipaytm + this.getpaypaytm + this.getdppaytm + this.getschemepaytm;
            this.totalphonepe = this.getemiphonepe + this.getpayphonepe + this.getdpphonepe + this.getschemephonepe;
            this.totalgooglepay = this.getemigooglepay + this.getpaygooglepay + this.getdpgooglepay + this.getschemegooglepay;
            this.totalother = this.getemiother + this.getpayother + this.getdpother + this.getschemeother;
            this.subtotal = this.totalcash + this.totalpatym + this.totalphonepe + this.totalother + this.totalgooglepay;
        },

        search() {
            this.$store.commit('assignloadingstatus', 1)
            let param = {
                todate: this.todate, fromdate: this.fromdate, entryfrom: this.entryfrom,
                entryto: this.entryto, generatedid: this.generatedid, payment: this.payment
            }
            window.axios.post('api/daybook/searchdata', param)
                .then(response => this.responseSearchData(response.data))
                .catch(error => console.log(error));
        },

        responseSearchData(data) {
            this.$store.commit('assignloadingstatus', 0);
            this.allpayments = data;
            this.record = this.allpayments.length;
            this.getEmiPayment();

        },

        cancel() {
            this.generatedid = '',
                this.payment = ''
        },
       
        
    },



}
</script>
    
   